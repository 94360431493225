<template>
  <div class="PerformStep">
    <div class="content">
      <div class="introduce">
        <img
          class="introduce-pic"
          src="../../assets/image/预付款保函.png"
          alt=""
        >
        <div class="introduce-text">
          <div class="text-tit">
            预付款保函
          </div>
          <div class="text-detail">
            预付款保函是指：承包人要求银行向业主(发包人) 出具的保证业主所支付的工程预付款用于实施项目的一种信用函件。
          </div>
          <a-button
            class="text-btn"
            type="primary"
            @click="$router.push({ path: '/performance', query: { type: '2' } })"
          >
            立即申请
          </a-button>
        </div>
      </div>
      <div class="describe">
        <div class="describe-list">
          <div class="list-tit">
            一、产品定义：
          </div>
          <div class="list-text">
            预付款保函是指：承包人要求银行向业主(发包人)出具的保证业主所支付的工程预付款用
            于实施项目的一种信用函件。投标人在中标后签订的合同中规定，承包人委托银行向业主出
            具的由业主按合同规定向承包人支付一笔工程预付款，及时用于实施项目的保证函件。担保
            银行应为业主所接受。如承包人不履约，业主可凭保函向担保银行索赔。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            二、担保方式：
          </div>
          <div class="list-text">
            ① 银行保函；<br>
            ② 专业担保公司的保函
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            三、担保额度:
          </div>
          <div class="list-text">
            承包商预付款担保的担保金额一般为工程建设合同价格（中标价格）的10%-30%。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            四、担保期限:
          </div>
          <div class="list-text">
            承包商预付款担保的有效期应当在保证合同中约定，一般不超过一年。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            五、客户申请提交资料清单：
          </div>
          <div class="list-text">
            1.企业基本资料：营业执照、财报报告、法人代表身份证等；2.工程资料：招标文件，中标
            通知书保函格式施工合同、项目要求的其他资料
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
};
</script>
<style scoped>
.content{
  width: 1500px;
  margin: 0 auto;
  padding: 30px 140px;
}
.introduce{
    display: flex;
    margin-bottom: 50px;
}
.introduce-text{
    margin-left: 30px;
}
.text-tit{
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 5px;
}
.text-detail{
    line-height: 32px;
    margin-left: 5px;
}
.describe-list{
    margin-bottom: 50px;
}
.list-tit{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
.list-text{
     font-size: 14px;
     line-height: 30px;
}
</style>
